<template>
  <div class="explore-overview-component mb-5">
    <h4 class="mb-3 section-title">{{ $t("g.Overview") }}</h4>
    <b-card
      :title="data.name"
      :img-src="
        data.image && data.image.length
          ? data.image[0].path
          : courseImagePlaceholder
      "
      img-alt="Card image"
      img-left
      class="mb-3"
    >
      <b-card-sub-title
        class="my-2"
        v-for="(year, index) in data.edu_years"
        :key="index"
      >
        {{ year.name }}
      </b-card-sub-title>

      <b-card-sub-title class="mb-2 date-title">
        <img :src="calendarSvg" alt="calendar" />
        {{ customDate }}
      </b-card-sub-title>

      <b-card-sub-title class="my-2 teachers-title">
        {{ $t("course_teachers") }}
      </b-card-sub-title>

      <b-card-sub-title
        class="my-2 mx-1 teachers-data"
        v-for="(teacher, index) in data.teachers"
        :key="index"
      >
        <b-link :to="`/show-teacher/${teacher.user && teacher.user.type_id }`">
          {{ `${teacher.user && teacher.user.first_name} ${teacher.user &&  teacher.user.last_name}` }}
        </b-link>
      </b-card-sub-title>

      <b-card-text>
        {{ data.description }}
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BLink, BCardText, BCardSubTitle } from "bootstrap-vue";
import { formatDateToCustomString } from "@/composables/format-date/format-date.js";

export default {
  components: {
    BLink,
    BCard,
    BCardText,
    BCardSubTitle,
  },
  props: ["data"],
  data() {
    return {
      calendarSvg: require("@/assets/images/icons/calendar.svg"),
      courseImagePlaceholder: require("@/assets/images/placeholders/courseImage.png"),
    };
  },
  computed: {
    customDate() {
      return (this.course_data = formatDateToCustomString(
        this.data.start_date,
        this.data.end_date
      ));
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
